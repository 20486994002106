import { SelectOption } from '@/models'

export namespace Utils {
  export function calculateShortIncome(amount: number, percent: number, period_days: number): string {
    if (!amount || !percent || !period_days) {
      return (0).toFixed(2)
    }
    const year = new Date().getFullYear()
    const days = ((year % 4 === 0 && year % 100 > 0) || year % 400 === 0) ? 366 : 365
    const mainDebt = Math.ceil((amount * percent / 100 / days) * 1000) / 1000
    return ((mainDebt * period_days) || 0).toFixed(2)
  }

  export function calculateShortOutcome(amount: number, percent: number, period_days: number): string {
    if (!amount || !percent || !period_days) {
      return (0).toFixed(2)
    }
    const year = new Date().getFullYear()
    const days = ((year % 4 === 0 && year % 100 > 0) || year % 400 === 0) ? 366 : 365

    const mainDebt = Math.ceil((amount * percent / 100 / days) * 1000) / 1000
    return ((mainDebt * period_days + amount) || 0).toFixed(2)
  }

  export function mapTradesFn(trade) {
    return {
      ...trade,
      signed_at: trade.signed_at ? new Date(trade.signed_at.endsWith('Z') ? trade.signed_at : trade.signed_at + 'Z').toLocaleString() : '-',
      amount: trade.loan_request?.amount,
      period_days: trade.loan_request?.period_days + ' д.',
      interest_rate: trade.loan_request?.interest_rate,
      due_date: trade.due_date ? new Date(trade.due_date).toLocaleDateString() : '-'
    }
  }

  export function mapOfferFn(offer) {
    return {
      ...offer,
      outcome: Utils.calculateShortOutcome(+offer.amount, +offer.interest_rate, +offer.period_days),
      income: Utils.calculateShortIncome(+offer.amount, +offer.interest_rate, +offer.period_days),
      period_days_mod: offer.period_days + ' д.'
    }
  }
  export function downloadBlob(blob: Blob, fileName: string): void {
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    a.click()
  }

  export function isYesterday(date) {
    return new Date(date.toDateString()) < new Date(new Date().toDateString())
  }

  export function formatFileName(fileName) {
    if (fileName.length > 25) {
      return fileName.slice(0, 25) + '...'
    }
    return fileName
  }

  export function formatFileSize(bytes) {
    if (bytes < 1024) {
      return `${bytes} bytes`
    } else if (bytes >= 1024 && bytes < 1048576) {
      return `${(bytes / 1024).toFixed(1)} KB`
    } else if (bytes >= 1048576) {
      return `${(bytes / 1048576).toFixed(1)} MB`
    }
  }

  export function validateFile(file: File): boolean {
    return validateFileSize(file.size) && validateFileExtension(file)
  }

  export function validateFileSize(size: number): boolean {
    return size < (10 * 1024 * 1024)
  }

  export function validateFileExtension(file: File): boolean {
    const validExtensions = /\.(pdf|jpeg|jpg|png|jip|tiff)$/
    return validExtensions.test(file.name)
  }

  export function debounce(fn, timeOutMs = 300) {
    return function perform(...args) {
      const previousCall = this.lastCall
      this.lastCall = Date.now()

      if (previousCall && this.lastCall - previousCall <= timeOutMs) {
        clearTimeout(this.lastCallTimer)
      }
      this.lastCallTimer = setTimeout(() => fn.apply(this, args), timeOutMs)
    }
  }

  export function mapSelectedOptionFromOrigin(values: any[], [valueKey, labelKey]: [string, string], mapperFn?: (item?: any, index?: number) => SelectOption): SelectOption[] {
    if (mapperFn) {
      return values.map(mapperFn)
    }
    return values.map(item => ({ value: item[valueKey], label: item[labelKey] }))
  }
}

export function addZeroTimezone(date: string): string {
  return date.endsWith('Z') ? date : `${date}Z`
}
