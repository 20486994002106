import { Country, PagedResult } from '@/models'
import axios from './axios'

const baseUrl = '/geo/country'

export default class CountryService {
  static getCountries(): Promise<PagedResult<Country>> {
    return axios.get(`${baseUrl}/?page_size=350`)
  }

  static getCountryNameByCode(code: string): Promise<Country> {
    return axios.get(`${baseUrl}/${code}/`)
  }
}
