
import ProfileSettings from '@/services/ProfileSettings'
import { Country, OtpAction, UserAddress, UserProfile } from '@/models'
import { mapGetters, mapState } from 'vuex'
import BaseCheckbox from '@/components/base/BaseCheckbox.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseSelect from '@/components/base/BaseSelect.vue'
import CONSTANTS from '../../constants/constants'
import BaseModal from '@/components/base/BaseModal.vue'
import OtpModal from '@/views/Settings/components/OtpModal.vue'
import CountryService from '@/services/Country'
import { Utils } from '@/helpers/utils'
import mapSelectedOptionFromOrigin = Utils.mapSelectedOptionFromOrigin
import BaseSelectNew from '@/components/base/BaseSelectNew.vue'
import { ValidationObserver } from 'vee-validate'

enum EmailModal {
  NONE,
  SENT,
  LIMIT
}

interface Data {
  living_address: UserAddress,
  living_address_eq_reg: boolean,
  phone: string,
  email: string,
  otp_code: string,
  codeword: string,
  code_requested: boolean,
  loading: boolean,
  emailModal: EmailModal,
  isEmailConfirming: boolean,
  country_code: string
  country_codes: Country[]
}

export default {
  components: {
    BaseModal,
    BaseSelect,
    BaseCheckbox,
    BaseButton,
    ValidationObserver,
    BaseSelectNew
  },
  data(): Data {
    return {
      living_address: new UserAddress(),
      living_address_eq_reg: false,
      phone: null,
      email: null,
      otp_code: null,
      codeword: null,
      code_requested: false,
      loading: false,
      emailModal: EmailModal.NONE,
      isEmailConfirming: false,
      country_code: '',
      country_codes: []
    }
  },
  async created() {
    const {
      living_address,
      living_addr_eq_reg
    } = await ProfileSettings.getContactInfo()
    const { results } = await CountryService.getCountries()
    this.country_codes = mapSelectedOptionFromOrigin(results, ['code', 'code'], item => ({
      value: item.code,
      label: `${item.code} - ${item.name_ru}`
    }))

    this.living_address = living_address
    this.country_code = living_address.country.code
    this.living_address.region = living_address.region ?? '-'
    this.living_address_eq_reg = living_addr_eq_reg
    const {
      phone,
      email,
      codeword
    } = this.profile
    this.email = email
    this.phone = phone
    this.codeword = codeword
  },
  computed: {
    CONSTANTS() {
      return CONSTANTS
    },
    ...mapGetters(['profile']),
    isEmailEqualsInitialValue() {
      return this.email === this.profile.email
    },
    emailModalStatus(): typeof EmailModal {
      return EmailModal
    },
    ...mapState(['modal'])
  },
  watch: {
    profile(newValue: UserProfile) {
      const {
        phone,
        email,
        codeword
      } = newValue
      this.email = email
      this.initial_email = email
      this.phone = phone
      this.codeword = codeword
    }
  },
  methods: {
    async confirmEmail() {
      try {
        const isValid = await this.$refs.emailObserver.validate()
        if (isValid && (!this.isEmailEqualsInitialValue || !this.profile.email_verified)) {
          this.isEmailConfirming = true
          await ProfileSettings.confirmEmail(this.email)
          this.emailModal = EmailModal.SENT
        }
      } catch (e) {
        if (e.message === 'too_many_attempts_to_update_email') {
          this.emailModal = EmailModal.LIMIT
        } else if (e.message === 'new_email_invalid') {
          this.$refs.email.applyValidation('Невалидный email')
        }
      } finally {
        this.isEmailConfirming = false
      }
    },
    async onClose() {
      this.$store.dispatch('getProfile')
      const {
        living_address,
        living_addr_eq_reg
      } = await ProfileSettings.getContactInfo()
      this.living_address = living_address
      this.living_address_eq_reg = living_addr_eq_reg
      this.$store.dispatch('getProfile')
      this.loading = false
    },
    async update(): Promise<void> {
      const isValid = await this.$refs.observer.validate()
      if (!this.country_code) {
        return
      }
      if (!isValid) {
        return
      }
      this.loading = true
      this.$store.dispatch('modal/openModal', {
        component: OtpModal,
        props: {
          sendOtp: async() => {
            await ProfileSettings.requestOtp(this.phone, OtpAction.UPDATE_PERSONAL_INFO)
          },
          onSubmit: this.request.bind(this),
          phone: this.phone
        },
        disableClose: true,
        onClose: this.onClose.bind(this)
      })
    },
    handleIsAddressTheSame() {
      this.living_address = new UserAddress()
    },
    async request(otp_code: string) {
      await ProfileSettings.updateContactInfo({
        codeword: this.codeword,
        living_address_eq_reg: this.living_address_eq_reg,
        living_address: { ...this.living_address, country_code: this.country_code, region: this.living_address.region === '-' ? null : this.living_address.region },
        otp_code,
        phone: this.phone
      })
    }
  }
}
