import { TradeStatus } from '@/models'

const COUNTRY_TYPE_LIST = ['112', '643']
const FORMER_NAME_TYPE_LIST = ['1', '2', '3', '4', '5', '6', '7', '8', '9']
const ID_TYPE_LIST = ['1', '2', '3', '4', '5', '6']
const CITIZENSHIP_TYPE_LIST = ['112', '643']
const MAIN_DOCUMENT_TYPE_LIST = ['1', '2', '3', '4', '5']
const EMPLOYMENT_TYPE_LIST = ['officially', 'tmp_unemployed', 'maternity_leave', 'entrepreneur', 'other_source', 'pensioner', 'working_pensioner', 'self_employed', 'student']
const SCOPE_OF_EMPLOYMENT_TYPE_LIST = ['not_indicated', 'finance', 'military', 'government', 'healthcare', 'it', 'public_utilities', 'culture', 'education', 'industry', 'entertainment', 'agriculture', 'construction', 'trading', 'services']
const POST_TYPE_LIST = ['not_indicated', 'specialist', 'worker', 'middle_manager', 'chief_accountant', 'chief_engineer', 'deputy_head', 'head', 'other']
const EDUCATION_TYPE_LIST = ['incomplete_secondary', 'secondary', 'specialized_secondary', 'incomplete_higher', 'higher']
const FAMILY_STATUS_TYPE_LIST = ['single', 'married']
const REGIONS = [
  '-',
  'Минская',
  'Брестская',
  'Витебская',
  'Гомельская',
  'Гродненская',
  'Могилевская'
]
const LOCALITY_TYPES = [
  'город',
  'деревня',
  'агрогородок',
  'местечко',
  'поселок',
  'поселок городского типа',
  'сельсовет',
  'станица',
  'хутор',
  'село'
]
const STREET_TYPE = [
  'улица',
  'аллея',
  'бульвар',
  'магистраль',
  'набережная',
  'переулок',
  'площадь',
  'проезд',
  'проспект',
  'проулок',
  'разъезд',
  'спуск',
  'тупик',
  'тракт',
  'шоссе'
]

export default {
  COUNTRY_TYPE_LIST,
  FORMER_NAME_TYPE_LIST,
  ID_TYPE_LIST,
  CITIZENSHIP_TYPE_LIST,
  MAIN_DOCUMENT_TYPE_LIST,
  EMPLOYMENT_TYPE_LIST,
  SCOPE_OF_EMPLOYMENT_TYPE_LIST,
  POST_TYPE_LIST,
  EDUCATION_TYPE_LIST,
  FAMILY_STATUS_TYPE_LIST,
  REGIONS,
  LOCALITY_TYPES,
  STREET_TYPE
}

export enum UserFamilyStatus {
  single = 'single',
  married = 'married'
}

export enum UserEducation {
  incomplete_secondary = 'incomplete_secondary',
  secondary = 'secondary',
  specialized_secondary = 'specialized_secondary',
  incomplete_higher = 'incomplete_higher',
  higher = 'higher'
}

export enum EmploymentType {
  incomplete_secondary = 'incomplete_secondary',
  secondary = 'secondary',
  specialized_secondary = 'specialized_secondary',
  incomplete_higher = 'incomplete_higher',
  higher = 'higher'
}

export enum EmploymentArea {
  not_indicated = 'not_indicated',
  finance = 'finance',
  military = 'military',
  government = 'government',
  healthcare = 'healthcare',
  it = 'it',
  public_utilities = 'public_utilities',
  culture = 'culture',
  education = 'education',
  industry = 'industry',
  entertainment = 'entertainment',
  agriculture = 'agriculture',
  construction = 'construction',
  trading = 'trading',
  services = 'services'
}

export enum EmploymentPosition {
  not_indicated = 'not_indicated',
  specialist = 'specialist',
  worker = 'worker',
  middle_manager = 'middle_manager',
  chief_accountant = 'chief_accountant',
  chief_engineer = 'chief_engineer',
  deputy_head = 'deputy_head',
  head = 'head',
  other = 'other'
}

export const borrowTradeRequestParams = {
  status: [TradeStatus.ACTIVE, TradeStatus.OVERDUE, TradeStatus.MAX_OVERDUE, TradeStatus.TRANSACTION_PENDING, TradeStatus.NEED_SIGN]
}

export const lenderTradeRequestParams = {
  status: [TradeStatus.ACTIVE, TradeStatus.OVERDUE, TradeStatus.MAX_OVERDUE, TradeStatus.TRANSACTION_PENDING, TradeStatus.NEED_SIGN]
}

export const documentsCategories = ['service_rules', 'paid_services_agreement', 'loan_agreement_template', 'scoring_statement', 'paritet_offert', 'personal_data_processing']
