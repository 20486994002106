import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import VeeValidate, { Validator } from 'vee-validate'
import Maska from 'maska'
import VueAxios from 'vue-axios'
import axios from 'axios'
import VueClickOutside from 'vue-click-outside'
import ru from '@/localization/ru'
import en from '@/localization/en'
import BaseTextInput from '@/components/base/BaseTextInput.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import { initSettingsMode, settings } from '@/services/Settings'
import { EnvMode, StorageKey } from '@/models'
import AuthService from '@/services/Auth'
import http from '@/services/axios'
import VTooltip from 'v-tooltip'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import { addZeroTimezone } from '@/helpers/utils'
import AuthoCheckModal from './components/modals/AuthoCheckModal.vue'

Vue.config.productionTip = false
Vue.config.productionTip = false

Validator.extend('organizationPhoneNumber', {
  getMessage: () => 'Номер телефона не верен',
  validate: value => {
    const filteredValue = value.replace(/[^\d]/g, '')

    return /(375)\d{9,9}/.test(filteredValue)
  }
})

Validator.extend('offerCount', {
  getMessage: () => 'Поле не верно',
  validate: value => {
    return +value > 0
  }
})

Validator.extend('offerRating', {
  getMessage: () => 'Поле не верно',
  validate: value => {
    return +value >= 0 && +value <= 100
  }
})

Validator.extend('offerPercent', {
  getMessage: () => 'Поле не верно',
  validate: value => {
    const minPercent = settings.offer.minPercent
    const maxPercent = settings.offer.maxPercent
    return +value >= minPercent && +value <= maxPercent
  }
})

Validator.extend('offerTerm', {
  getMessage: () => 'Поле не верно',
  validate: value => {
    const minTerm = settings.offer.minTerm
    const maxTerm = settings.offer.maxTerm
    return +value >= minTerm && +value <= maxTerm
  }
})

Validator.extend('offerLongTerm', {
  getMessage: () => 'Поле не верно',
  validate: value => {
    const minTerm = settings.longOffer.minTermMonths
    const maxTerm = settings.longOffer.maxTermMonths
    return +value >= minTerm && +value <= maxTerm
  }
})

Validator.extend('offerAmount', {
  getMessage: () => 'Поле не верно',
  validate: value => {
    const minAmount = settings.offer.minAmount
    const maxAmount = settings.offer.maxAmount
    return +value >= minAmount && +value <= maxAmount
  }
})

Validator.extend('totalIncome', {
  getMessage: () => 'Поле не верно',
  validate: value => value.length >= 2
})

Validator.extend('dependensNumber', {
  getMessage: () => 'Поле не верно',
  validate: value => value.length >= 1
})

Validator.extend('workExperience', {
  getMessage: () => 'Поле не верно',
  validate: value => value.length >= 1
})

Validator.extend('otherPositionType', {
  getMessage: () => 'Поле не верно',
  validate: value => value.length >= 2
})

Validator.extend('nameOfOrganization', {
  getMessage: () => 'Поле не верно',
  validate: value => value.length >= 2
})

Validator.extend('livingAddress', {
  getMessage: () => 'Поле не верно',
  validate: value => value.length >= 2
})

Validator.extend('registrationAdress', {
  getMessage: () => 'Поле не верно',
  validate: value => value.length >= 2
})

Validator.extend('idIssuedBy', {
  getMessage: () => 'Поле не верно',
  validate: value => value.length >= 2
})

Validator.extend('idSeries', {
  getMessage: () => 'Поле не верно',
  validate: value => value.length >= 2
})

Validator.extend('idNumber', {
  getMessage: () => 'Поле не верно',
  validate: value => value.length >= 7
})

Validator.extend('birthPlace', {
  getMessage: () => 'Поле не верно',
  validate: value => value.length >= 2
})

Validator.extend('keyWord', {
  getMessage: () => 'Поле не верно',
  validate: value => value.length >= 2
})

Validator.extend('citizenship', {
  getMessage: () => 'Поле не верно',
  validate: value => value.length >= 2
})

Validator.extend('name', {
  getMessage: () => 'Поле не верно',
  validate: value => value.length >= 2
})

Validator.extend('middleName', {
  getMessage: () => 'Поле не верно',
  validate: value => value.length >= 2
})

Validator.extend('surname', {
  getMessage: () => 'Поле не верно',
  validate: value => value.length >= 2
})

Validator.extend('msisdn', {
  getMessage: () => 'Номер телефона не верен',
  validate: value => {
    const filteredValue = value.replace(/[^\d]/g, '')

    return /(375)\d{9,9}/.test(filteredValue)
  }
})

Validator.extend('password', {
  getMessage: () => 'Не менее 8 символов, минимум одна прописная и одна строчная буква, одна цифра',
  validate: value => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(value)
})

Validator.extend('one_lower_latin', {
  getMessage: () => 'минимум один латинский символ в нижнем регистре',
  validate: value => /[a-z]+/g.test(value)
})

Validator.extend('one_special_symbol', {
  getMessage: () => 'минимум один специальный символ @$!%*?&',
  validate: value => /[()[\]{}|\\`~!@#$^&*_\-+=;:'",<>./?]+/g.test(value)
})

Validator.extend('one_upper_latin', {
  getMessage: () => 'минимум один латинский символ в верхнем регистре',
  validate: value => /[A-Z]+/g.test(value)
})

Validator.extend('one_number', {
  getMessage: () => 'минимум одна цифра',
  validate: value => /[0-9]+/g.test(value)
})
Validator.extend('min_length', {
  getMessage: () => 'минимальная длина 8 символов',
  validate: value => /^.{8,}$/g.test(value)
})

Validator.extend('login', {
  getMessage: () => 'Логин не верен',
  validate: value => value.length >= 6
})

Validator.extend('passwordConfirmation', {
  getMessage: () => 'Пароли не совпадают',
  validate: (value, [otherValue]) => {
    return value === otherValue
  },
  hasTarget: true
} as any)

Validator.extend('loanPayConfirmation', {
  getMessage: () => 'Не верная сумма',
  validate: (value, [otherValue]) => {
    return Number((+value * 100).toFixed(0)) <= otherValue
  },
  hasTarget: true
} as any)

Validator.extend('otp', {
  getMessage: () => 'Код не верен',
  validate: value => value.length >= 4
})

Vue.filter('cardPan', function(value) {
  const parsedPan = value.match(/.{1,4}/g)
  return parsedPan.join(' ').replace(/x/g, '*').toUpperCase()
})

Vue.filter('cardExpiry', function(value) {
  return value.slice(5, 7) + '/' + value.slice(2, 4)
})

Vue.filter('offerUserName', function(value) {
  const name = value?.first_name
  const familyName = value?.last_name
  const [modifiedFamilyName] = familyName
  return `${name} ${modifiedFamilyName}`
})

Vue.filter('numberFixed', (value: number) => {
  return value.toFixed(2)
})

Vue.filter('getTime', function(date?: string) {
  if (!date) {
    return '-'
  }
  const dateObject = new Date(addZeroTimezone(date))
  return `${dateObject.getHours()}:${dateObject.getMinutes()}`
})

Vue.filter('getDate', function(date?: string) {
  return date ? new Date(addZeroTimezone(date)).toLocaleDateString('ru') : '-'
})

Vue.filter('getDateTime', function(date: string) {
  return date ? new Date(addZeroTimezone(date)).toLocaleString('ru') : '-'
})

Vue.filter('replace', function(value, symbol, separator) {
  return value ? value.replaceAll(symbol, separator) : '-'
})

Vue.filter('currency', function(value: number, currency: string): string | null {
  return value ? `${value} ${currency}` : null
})

Vue.filter('formatPhone', function(value: string): string {
  if (!value) {
    return ''
  }
  return value.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3 $4 $5')
})

Vue.filter('timeDate', function(value?: string, separator?: string) {
  return value ? new Date(value).toLocaleString().replaceAll('/', separator ?? '/').split(',').reverse().join(', ') : '-'
})

Vue.component('base-button', BaseButton)
Vue.component('base-text-input', BaseTextInput)

const toastOptions = {
  toastClassName: 'toast-custom-container',
  timeout: 2000
}

Vue.use(VueAxios, axios)
Vue.use(VueI18n)
Vue.use(Maska)
Vue.use(VeeValidate)
Vue.use(VueClickOutside)
Vue.use(VTooltip)
Vue.use(Toast, toastOptions)

const dictionary = {
  ru: {
    messages: {
      required: 'Поле обязательное',
      _default: 'Поле невалидно',
      email: 'Невалидный email',
      regex: 'Не верный формат',
      one_upper_latin: 'минимум один латинский символ в верхнем регистре',
      one_number: 'минимум одна цифра',
      one_lower_latin: 'минимум один латинский символ в нижнем регистре',
      one_special_symbol: 'минимум один специальный символ @$!%*?&',
      min_length: 'минимальная длина 8 символов',
      passwordConfirmation: 'Пароли не совпадают',
      min: (val, params) => `Минимальная длина ${params} символа`,
      max: (val, params) => `Максимальная длина ${params} символа`
    }
  }
}

Validator.localize('ru', dictionary.ru)

const isBetween = (value, {
  min,
  max
}) => Number(value) >= Number(min) && Number(value) <= Number(max)

Validator.extend('isBetween', {
  getMessage: (field, params) => `Значение должно быть в пределах ${params[0]} - ${params[1]} ${params[2] ? params[2] : ''}`,
  validate: isBetween
}, { paramNames: ['min', 'max', 'type'] })

const locale = 'ru'
export const i18n = new VueI18n({
  locale,
  messages: {
    ru,
    en
  }
})

http.interceptors.request.use(async config => {
  config.headers.locale = localStorage.getItem('locale')
  const token = await getAccessToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

http.interceptors.response.use(function(response) {
  return response.data
}, async function(error) {
  const code = Array.isArray(error.response?.data) ? error.response?.data[0]?.code : error.response?.data?.code
  if (error?.response?.status === 400) {
    if (error.response.config.url === '/user/sign-in/' || error.response.config.url === '/user/sign-in/otp/') {
      if (code === 'user_blocked_by_biometry' || code === 'user_blocked') {
        throw new Error(code)
      }
    }
  }
  if (error?.response?.status === 401) {
    if (error.response.config.url === '/user/sign-in/' || error.response.config.url === '/user/sign-in/otp/') {
      await store.dispatch('setError', catchErrorMessage(error.response?.data))
      throw new Error()
    }
    await store.dispatch('purgeAuth')
  }
  if (error?.response?.status === 403) {
    if (code === 'forbidden_due_block') {
      localStorage.removeItem(StorageKey.ACCESS)
      await store.dispatch('purgeProfile')
      router.push({
        name: 'SignIn',
        params: { isSimpleBlocked: 'true' }
      }).catch(e => console.log(e))
      throw new Error('403')
    }
  }

  if (code === 'individual_check_not_completed') {
    await store.dispatch('modal/openModal', {
      component: AuthoCheckModal,
      props: {
        message: catchErrorMessage(error.response?.data)
      },
      disableClose: true
    })
    return Promise.reject(new Error(code))
  }

  if (error?.response?.config.url === '/msi/biometry/confirmation/') {
    return Promise.reject(new Error(code))
  }
  if (code === 'insufficient_funds_on_the_card' || code === 'insufficient_funds_on_the_card_while_accepting') {
    throw error.response.data.code
  }
  if (code === 'too_many_attempts_to_update_email' || code === 'new_email_invalid') {
    return Promise.reject(new Error(code))
  }

  if (error?.response?.config.url === '/bank-card/' || error?.response?.config.url === '/user/update-personal-info/') {
    if (code !== 'financial_operations_blocked') {
      return Promise.reject(new Error(code))
    }
  }

  await store.dispatch('setError', catchErrorMessage(error.response?.data))
  return Promise.reject(new Error(code))
})

function getAccessToken(): Promise<string> {
  const token = localStorage.getItem(StorageKey.ACCESS)
  if (!token) {
    return Promise.resolve(null)
  }
  const tokenInfo = JSON.parse(atob(token.split('.')[1]))
  const expTokenTime = tokenInfo.exp * 1000
  const date = new Date().getTime()
  const timeToRefreshToken = settings.refreshTokenTime
  if (expTokenTime - date <= timeToRefreshToken) {
    return AuthService.refreshToken()
  }
  return Promise.resolve(token)
}

function catchErrorMessage(data: { code: string } | { code: string }[]) {
  const code = Array.isArray(data) ? data[0]?.code : data?.code
  return i18n.t(`error.${code}`) || 'Ошибка сервиса'
}

axios.get('/env.json')
  .then((res) => res.data)
  .then((data: { environment: EnvMode | null }) => {
    initSettingsMode(data?.environment)
    new Vue({
      router,
      store,
      i18n,
      render: h => h(App)
    }).$mount('#app')
  })
