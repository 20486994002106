
import { mapActions, mapGetters, mapState } from 'vuex'
import BaseTextInput from '../../components/base/BaseTextInput.vue'
import BaseButton from '../../components/base/BaseButton.vue'
import BaseModal from '../../components/base/BaseModal.vue'
import OffersService from '@/services/Offers'
import { Utils } from '@/helpers/utils'
import { LoanRequest, LoanRequestStatus, LoanRequestType, StorageKey, TradeStatus, UserStatus } from '@/models'
import BaseTable from '@/components/base/BaseTable.vue'
import ClickOutside from 'vue-click-outside'
import BaseBadge from '@/components/base/BaseBadge.vue'
import CreateClaim from '@/views/Claim/index.vue'
import VueContext from 'vue-context'
import BaseCheckbox from '@/components/base/BaseCheckbox.vue'
import LoanService from '@/services/Loan'
import ActionCard from '@/components/common/ActionCard.vue'
import { BiometryStatus, VerificationStatus } from '@/models/biometry-status.enum'
import MsiService from '@/services/Msi'
import { ValidationObserver } from 'vee-validate'
import { CalculatorService } from '@/services/Calculator.service'
import BasePagination from '@/components/base/BasePagination.vue'
import BaseSelect from '@/components/base/BaseSelect.vue'
import Copy from '@/components/base/Copy.vue'
import { BlockReasonEnum } from '@/models/block-reason.enum'

const tradeColumns = [
  {
    name: 'agreement_id',
    localization: 'message.profile.lender.activeOffers.items.agreement_id'
  },
  {
    name: 'display_name',
    localization: 'message.profile.lender.activeOffers.items.borrower'
  },
  {
    name: 'borrower_rating',
    localization: 'message.profile.borrower.activeOffers.items.borrower_rating'
  },
  {
    name: 'signed_at',
    localization: 'message.profile.borrower.activeOffers.items.issued'
  },
  {
    name: 'amount',
    localization: 'message.profile.borrower.activeOffers.items.amount'
  },
  {
    name: 'period_days',
    localization: 'message.profile.borrower.activeOffers.items.term'
  },
  {
    name: 'interest_rate',
    localization: 'message.profile.borrower.activeOffers.items.percent'
  },
  {
    name: 'initial_debt',
    localization: 'message.profile.borrower.activeOffers.items.income'
  },
  {
    name: 'due_date',
    localization: 'message.profile.borrower.activeOffers.items.issuedBy'
  },
  {
    name: 'total_penalty',
    localization: 'message.profile.borrower.activeOffers.items.peni'
  },
  {
    name: 'remaining_debt',
    localization: 'message.profile.borrower.activeOffers.items.totalAmount'
  },
  {
    name: 'status',
    localization: 'message.profile.borrower.activeOffers.items.status'
  },
  {
    name: 'last_claim_signed_at',
    localization: ''
  },
  {
    name: 'last_legal_action_signed_at',
    localization: ''
  },
  { name: 'action' }
]

export default {
  name: 'LenderProfile',
  components: {
    Copy,
    BaseSelect,
    BasePagination,
    ValidationObserver,
    ActionCard,
    BaseCheckbox,
    CreateClaim,
    BaseBadge,
    BaseTable,
    BaseTextInput,
    BaseButton,
    BaseModal,
    VueContext
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      request: new LoanRequest(),
      isBtnLoading: false,
      isOfferMenuOpen: null,
      isClaimMenuOpen: null,
      isShowExpiredModal: false,
      claimId: null,
      isClaim: false,
      requestLoading: false,
      requestColumns: [
        {
          name: 'amount',
          localization: 'message.profile.lender.activeOffers.items.amount'
        },
        {
          name: 'period_days_mod',
          localization: 'message.profile.lender.activeOffers.items.term'
        },
        {
          name: 'interest_rate',
          localization: 'message.profile.lender.activeOffers.items.percent'
        },
        {
          name: 'total_depth',
          localization: 'message.profile.lender.activeOffers.items.income'
        },
        {
          name: 'rating',
          localization: 'message.profile.lender.activeOffers.items.rating'
        },
        {
          name: 'loans_count',
          localization: 'message.profile.lender.activeOffers.items.count'
        },
        {
          name: 'status',
          localization: 'message.profile.lender.activeOffers.items.status'
        },
        { name: 'action' }
      ],
      isBiometryProcessing: false,
      isBiometryConfirmation: false,
      verificationStatus: VerificationStatus.NONE,
      balanceError: false,
      balanceErrorText: '',
      showBlockModal: false,
      expectedIncome: null,
      isCalculating: false,
      loansFilters: {},
      offersFilters: {},
      loansStatuses: [TradeStatus.ACTIVE, TradeStatus.NEED_SIGN, TradeStatus.OVERDUE],
      offersStatuses: [LoanRequestStatus.ACTIVE, LoanRequestStatus.DRAFT]
    }
  },
  async created() {
    if (!this.$route.query?.['save-page']) {
      this.$store.dispatch('lenderTrade/resetPage')
    }
    await this.$store.dispatch('lenderTrade/setPage')
    await this.resetOffersFilters()
    if (this.$route.query?.biometric === 'success') {
      this.verificationStatus = VerificationStatus.SUCCESS
    }
    if (this.$route.query?.biometric === 'failed') {
      this.verificationStatus = VerificationStatus.ERROR
    }
    if (this.$route.query?.biometric === 'confirmation-required') {
      this.verificationStatus = VerificationStatus.CONFIRM
    }
    if (this.$route.query?.biometric === 'biometry-failed-delay-block') {
      this.showBlockModal = true
    }
    if (this.$route.query?.biometric === 'biometric=biometry-failed-instant-block') {
      localStorage.removeItem(StorageKey.ACCESS)
      await this.$router.push({
        name: 'SignIn',
        params: { isBlocked: 'true' }
      })
    }
    if (this.profile.premium_loans) {
      this.requestColumns.unshift({
        name: 'premium',
        localization: ''
      })
    }
    this.loansFilters = { ...this.tradeFilters }
    this.offersFilters = { ...this.offerFilters }
  },
  methods: {
    ...mapActions({
      applyLoansFiltersAction: 'lenderTrade/applyFilters',
      resetLoansFiltersAction: 'lenderTrade/resetFilters',
      applyOffersFiltersAction: 'lenderOffer/applyFilters',
      resetOffersFiltersAction: 'lenderOffer/resetFilters'
    }),
    async pinToTop(value: boolean, id: number) {
      this.$store.dispatch('lenderOffer/setPinToTop', {
        id,
        value: value
      })
      try {
        value ? await LoanService.pinLenderLoan(id) : await LoanService.unpinLenderLoan(id)
      } catch (e) {
        this.$store.dispatch('lenderOffer/setPinToTop', {
          id,
          value: !value
        })
      }
    },
    async showMoreLoans(page: number): Promise<void> {
      await this.$store.dispatch('lenderTrade/setPage', page)
    },
    async showMoreRequests() {
      await this.$store.dispatch('lenderOffer/setPage', this.lenderOffer.page + 1)
    },
    openTransactions({ id }) {
      this.$router.push({
        name: 'loan',
        params: { id }
      })
    },
    openExpiredModal(id: number, isClaim?: boolean) {
      this.claimId = id
      this.isClaimMenuOpen = false
      this.isShowExpiredModal = true
      this.isClaim = isClaim
      this.$refs.menu.close()
    },
    closeExpiredModal() {
      this.isShowExpiredModal = false
      this.isClaim = false
      this.claimId = null
    },
    async createOffer() {
      try {
        this.requestLoading = true
        if (this.request.period_type === LoanRequestType.LONG) {
          this.request.period_days *= 29.5
        }
        const {
          amount,
          period_days,
          interest_rate,
          rating,
          loans_count
        } = this.request
        await OffersService.createOffer({
          amount,
          period_days,
          interest_rate,
          rating,
          loans_count
        })
        this.$toast.success(this.$t('message.profile.lender.offer.toastMsg'))
        this.$emit('createOffer')
        this.requestLoading = false
        this.request = new LoanRequest()
      } catch (error) {
        if (error === 'insufficient_funds_on_the_card' || error === 'insufficient_funds_on_the_card_while_accepting') {
          this.balanceError = true
          this.balanceErrorText = this.$t(`error.${error}`)
        }
      }
    },
    async changeOfferStatus({
      request,
      status
    }) {
      this.$refs.requestMenu.close()
      try {
        await OffersService.changeLenderRequestStatus(request, status)
        // TODO thought about replace instead get full list
        await this.$store.dispatch('lenderOffer/setPage', 1)
      } catch (error) {
        if (error === 'insufficient_funds_on_the_card' || error === 'insufficient_funds_on_the_card_while_accepting') {
          this.balanceError = true
          this.balanceErrorText = this.$t(`error.${error}`)
        }
      }
    },
    async processBiometry() {
      try {
        this.isBiometryProcessing = true
        const { url } = await MsiService.getMsiBiometryLink()
        window.location.href = url
      } catch (e) {
        console.log(e)
      }
    },
    async confirmBiometry() {
      try {
        const track = this.$route.query?.track
        this.isBiometryConfirmation = true
        await MsiService.confirmMsiBiometry(track)
        this.verificationStatus = this.VerificationStatus.SUCCESS
      } catch (e) {
        this.verificationStatus = this.VerificationStatus.ERROR
      } finally {
        this.isBiometryConfirmation = false
        await this.$store.dispatch('updateProfile')
      }
    },
    goToCardsPage() {
      this.$router.push('cards')
    },
    goToSettings() {
      this.$router.push('settings')
    },
    goToVerifyEmail() {
      this.$router.push('change-contact-info')
    },
    goToUpdateWorkPlace() {
      this.$router.push({ name: 'change-profile' })
    },
    goToUpdateLiveAddress() {
      this.$router.push({ name: 'change-contact-info' })
    },
    updateMsiData() {
      this.$store.dispatch('requestMsiUpdate')
    },
    closeBiometryModal() {
      this.$router.push('profile')
      this.verificationStatus = this.VerificationStatus.NONE
    },
    debounceCalculateResult: Utils.debounce(async function(amount, interest_rate, period_days) {
      const isValid = await this.$refs.observer.validate()
      const isFieldsFulfilled = this.request.amount && this.request.interest_rate && this.request.period_days
      if (isValid && isFieldsFulfilled) {
        this.isCalculating = true
        const result = await CalculatorService.calculate({
          amount,
          interest_rate,
          period_days
        })
        this.isCalculating = false
        this.expectedIncome = result.percent_amount
      }
    }, 500),
    async applyLoansFilters() {
      const requestFilters = {
        ...this.loansFilters
      }
      await this.applyLoansFiltersAction(requestFilters)
    },
    async resetLoansFilters() {
      this.loansFilters.status = null
      await this.resetLoansFiltersAction()
    },
    async applyOffersFilters() {
      const requestFilters = {
        ...this.offersFilters
      }
      await this.applyOffersFiltersAction(requestFilters)
    },
    async resetOffersFilters() {
      this.offersFilters.status = null
      await this.resetOffersFiltersAction()
    }
  },
  computed: {
    BlockReasonEnum() {
      return BlockReasonEnum
    },
    isYesterday() {
      return Utils.isYesterday
    },
    UserStatus() {
      return UserStatus
    },
    TradeStatus() {
      return TradeStatus
    },
    tradeColumns() {
      return tradeColumns
    },
    LoanRequest() {
      return LoanRequest
    },
    ...mapState(['lenderTrade']),
    ...mapState(['lenderOffer']),
    ...mapGetters(['profile']),
    ...mapGetters('lenderTrade', ['tradeList', 'tradeFilters']),
    ...mapGetters('lenderOffer', ['offerList', 'offerFilters']),
    ...mapGetters(['validationRules']),
    isCreateOfferBtnDisabled() {
      return this.isHasValidationErrors || this.isHasEmptyFields
    },
    isHasValidationErrors() {
      return this.errors.items.length > 0
    },
    isHasEmptyFields() {
      return !this.request.interest_rate || !this.request.amount ||
          !this.request.period_days || !this.request.rating || !this.request.loans_count
    },
    amountOfferPlaceholder() {
      const amountFrom = this.validationRules.MIN_LOAN_REQUEST_AMOUNT
      const amountTo = this.validationRules.MAX_LOAN_REQUEST_AMOUNT
      return `${this.$t('message.profile.lender.offer.amount.placeholderStart')} ${amountFrom} ${this.$t('message.profile.lender.offer.amount.placeholderEnd')} ${amountTo}`
    },
    amountShortTermPlaceholder() {
      return `${this.$t('message.profile.lender.offer.term.placeholderStart')} ${this.validationRules.MIN_LOAN_REQUEST_PERIOD_DAYS} ${this.$t('message.profile.lender.offer.term.placeholderEnd')} ${this.validationRules.MAX_LOAN_REQUEST_PERIOD_DAYS} ${this.$t('message.profile.lender.offer.term.days')}`
    },
    amountPercentPlaceholder() {
      const percentFrom = this.validationRules.MIN_LOAN_REQUEST_INTEREST_RATE
      const percentTo = this.validationRules.MAX_LOAN_REQUEST_INTEREST_RATE
      return `${this.$t('message.profile.lender.offer.percent.placeholderStart')} ${percentFrom} ${this.$t('message.profile.lender.offer.percent.placeholderEnd')} ${percentTo}`
    },
    requestOverload(): boolean {
      return +this.expectedIncome > (+this.request.amount * 2)
    },
    biometryStatus() {
      return BiometryStatus
    },
    VerificationStatus(): typeof VerificationStatus {
      return VerificationStatus
    },
    msiLimitExceeded() {
      return this.profile.biometry_attempts === this.profile.msi_biometry_max_attempts
    },
    requestAmountRules() {
      return 'isBetween:' + this.validationRules.MIN_LOAN_REQUEST_AMOUNT + ',' + this.validationRules.MAX_LOAN_REQUEST_AMOUNT + ',' + 'BYN'
    },
    requestPeriodRules() {
      return 'isBetween:' + this.validationRules.MIN_LOAN_REQUEST_PERIOD_DAYS + ',' + this.validationRules.MAX_LOAN_REQUEST_PERIOD_DAYS + ',' + 'дней'
    },
    interestRateRules() {
      return 'isBetween:' + this.validationRules.MIN_LOAN_REQUEST_INTEREST_RATE + ',' + this.validationRules.MAX_LOAN_REQUEST_INTEREST_RATE + ',' + '%'
    },
    clonedRequest() {
      return Object.assign({}, this.request)
    },
    isShowActionCards() {
      return this.profile.msi_biometry === BiometryStatus.REQUIRED ||
      !this.profile.email_verified ||
      !this.profile.has_bank_card ||
      this.profile?.bepaid_checkout_notification?.show ||
      !this.profile.is_msi_data_actual ||
      !this.profile.is_work_info_actual ||
      !this.profile.is_living_address_actual
    },
    migrationTime() {
      return this.$options.filters.timeDate(this.profile?.bepaid_checkout_notification?.datetime, '.')
    },
    isNotChecked() {
      return !this.profile.checked
    }
  },
  watch: {
    clonedRequest: {
      handler: async function(newVal: LoanRequest, oldVal: LoanRequest) {
        const {
          amount,
          interest_rate,
          period_days,
          rating,
          loans_count
        } = newVal
        const isChangedProperValues = !(oldVal.loans_count !== loans_count || oldVal.rating !== rating)
        if (isChangedProperValues) {
          this.expectedIncome = null
          this.debounceCalculateResult(amount, interest_rate, period_days)
        }
      },
      deep: true
    }
  }
}
