<template>
  <div class="news-page-wrapper">
    <static-pages-header @mobileMenuToggle="mobileMenuToggle" />
    <section :class="{'heading-container-mobile-menu-open': isMobileMenuOpen}" class="news-container">
      <div class="news-title">{{ $t('message.newsPage.title') }}</div>
      <div class="date">20.01.2025</div>
      <div class="post-title">
        <div>Уважаемые <b>Пользователи и Клиенты</b> сервиса</div>
        <div>потребительского онлайн-заимствования «Капуста – онлайн-заимствования»!</div>
      </div>
      <div class="post-data">
        Информируем вас о том, что 30.01.2025 вступает в силу новая редакция «Правил сервиса потребительского онлайн-заимствования «Капуста – онлайн- заимствования»». Подробнее см. Документы
      </div>
    </section>
    <section :class="{'heading-container-mobile-menu-open': isMobileMenuOpen}" class="news-container">
      <div class="date">25.11.2024</div>
      <div class="post-title">
        <div>Уважаемые <b>Пользователи и Клиенты</b> сервиса</div>
        <div>потребительского онлайн-заимствования «Капуста – онлайн-заимствования»!</div>
      </div>
      <div class="post-data">
        Информируем вас о том, что 04.12.2024 вступает в силу новая редакция «Правил сервиса потребительского онлайн-заимствования «Капуста – онлайн- заимствования»», а также новая редакция «Договора возмездного оказания услуг». Подробнее см. Документы
      </div>
    </section>
    <section :class="{'heading-container-mobile-menu-open': isMobileMenuOpen}" class="news-container">
      <div class="date">23.10.2024</div>
      <div class="post-title">
        <div>Уважаемые <b>Пользователи и Клиенты</b> сервиса</div>
        <div>потребительского онлайн-заимствования «Капуста – онлайн-заимствования»!</div>
      </div>
      <div class="post-data">
        Информируем вас о том, что 01.11.2024 вступает в силу новая редакция «Правил сервиса потребительского
        онлайн-заимствования «Капуста – онлайн- заимствования»», а также новая редакция «Договора возмездного оказания
        услуг». Подробнее см. Документы
      </div>
    </section>
    <section :class="{'heading-container-mobile-menu-open': isMobileMenuOpen}" class="news-container">
      <div class="date">29.07.2024</div>
      <div class="post-title">
        <div>Уважаемые <b>Пользователи и Клиенты</b> сервиса</div>
        <div>потребительского онлайн-заимствования «Капуста – онлайн-заимствования»!</div>
      </div>
      <div class="post-data">
        Информируем вас о том, что 09.08.2024 вступает в силу новая редакция «Правил сервиса потребительского
        онлайн-заимствования «Капуста – онлайн- заимствования»», а также новая редакция «Договора возмездного оказания
        услуг». Подробнее см. Документы
      </div>
    </section>
    <section :class="{'heading-container-mobile-menu-open': isMobileMenuOpen}" class="news-container">
      <div class="date">15.05.2024</div>
      <div class="post-title">
        <div>Уважаемые <b>Пользователи и Клиенты</b> сервиса</div>
        <div>потребительского онлайн-заимствования «Капуста – онлайн-заимствования»!</div>
      </div>
      <div class="post-data">
        Информируем вас о том, что 24.05.2024 вступает в силу новая редакция «Правил сервиса потребительского
        онлайн-заимствования «Капуста – онлайн- заимствования»», а также новая редакция «Договора возмездного оказания
        услуг». Подробнее см. Документы
      </div>
    </section>
    <section :class="{'heading-container-mobile-menu-open': isMobileMenuOpen}" class="news-container">
      <div class="date">28.03.2024</div>
      <div class="post-title">
        <div>Уважаемые <b>Пользователи и Клиенты</b> сервиса</div>
        <div>потребительского онлайн-заимствования «Капуста – онлайн-заимствования»!</div>
      </div>
      <div class="post-data">
        Информируем вас о том, что 08.04.2024 вступает в силу новая редакция «Правил сервиса
        потребительского онлайн-заимствования «Капуста – онлайн- заимствования»», а также новая
        редакция «Договора возмездного оказания услуг». Подробнее см. Документы
      </div>
    </section>
    <section :class="{'heading-container-mobile-menu-open': isMobileMenuOpen}" class="news-container">
      <div class="date">26.02.2024</div>
      <div class="post-title">
        <div>Уважаемые <b>Пользователи и Клиенты</b> сервиса</div>
        <div>потребительского онлайн-заимствования «Капуста – онлайн-заимствования»!</div>
      </div>
      <div class="post-data">
        Информируем вас о том, что 06.03.2024 вступает в силу новая редакция «Правил сервиса
        потребительского онлайн-заимствования «Капуста – онлайн- заимствования»», а также новая
        редакция «Договора возмездного оказания услуг». Подробнее см. Документы
      </div>
    </section>
    <section :class="{'heading-container-mobile-menu-open': isMobileMenuOpen}" class="news-container">
      <div class="date">26.10.2023</div>
      <div class="post-title">
        <div>Уважаемые <b>Пользователи и Клиенты</b> сервиса</div>
        <div>потребительского онлайн-заимствования «Капуста – онлайн-заимствования»!</div>
      </div>
      <div class="post-data">
        Уважаемые клиенты! Информируем вас о том,
        что 13.12.2023 вступает в силу новая редакция «Правил сервиса
        потребительского онлайн-заимствования «Капуста – онлайн-
        заимствования»», а также новая редакция «Договора возмездного
        оказания услуг». Подробнее см. Документы
      </div>
      <!--      <div class="post-data">-->
      <!--        В связи с этим приносим извинения за временные неудобства и надеемся на ваше понимание.-->
      <!--      </div>-->
    </section>
    <section :class="{'heading-container-mobile-menu-open': isMobileMenuOpen}" class="news-container">
      <div class="date">26.05.2023</div>
      <div class="post-title">
        <div>Уважаемые <b>Пользователи и Клиенты</b> сервиса</div>
        <div>потребительского онлайн-заимствования «Капуста – онлайн-заимствования»!</div>
      </div>
      <div class="post-data">
        В соответствии с приказом директора ООО «ИФС» (№ 8-од от 26.05.2023) с 26.05.2023 начато промышленное
        тестирование и ввод в эксплуатацию комплекса программного обеспечения «Капуста – онлайн-заимствования». В данный
        период возможны временные ограничения по доступу к функционалу сервиса, обновления и технические работы на сайте
        kapusta.by (капуста.бел). Срок окончания вышеуказанных работ – 30.06.2023.
      </div>
      <div class="post-data">
        В связи с этим приносим извинения за временные неудобства и надеемся на ваше понимание.
      </div>
    </section>
  </div>
</template>

<script>
import StaticPagesHeader from '../../components/common/StaticPagesHeader.vue'

export default {
  name: 'News',
  components: {
    StaticPagesHeader
  },
  data() {
    return {
      isMobileMenuOpen: false
    }
  },
  methods: {
    mobileMenuToggle(value) {
      this.isMobileMenuOpen = value
    },
    signUp() {
      this.$router.push({ name: 'msi' })
    }
  }
}
</script>

<style lang="scss" scoped>
.news-page-wrapper {
  @include base-page-wrapper;
  @include static-heading-container;
}

.news-container {
  @include base-page-container;

  padding: 50px 50px 5rem 50px;
  box-sizing: border-box;
  flex-direction: column;
}

.news-title {
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 40px;
  line-height: 125%;
  color: #181A1B;
  align-self: flex-start;
}

.news-block {
  width: 810px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.date {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.post-title {
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 22px;
  line-height: 135%;
  color: #121212;
}

.post-data {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 22px;
  line-height: 135%;
  color: #121212;
}

@media screen and (max-width: 820px) {
  .news-container {
    padding: 50px 0 5rem 0;
  }

  .news-title {
    font-size: 30px;
  }
}
</style>
