<template>
  <div class="flex flex-col items-center p-5 pt-8 max-w-[480px] text-center">
    <img class="mb-5" src="../../../public/custom/images/pending-autocheck.svg" alt="pending autocheck">
    <h1 class="text-xl mb-3 font-semibold">{{  $t('user_auto_checks') }}</h1>
    <h2 class="mb-10 text-sm">{{ message }}</h2>
      <base-button
          :text="$t('Continue')"
          @click="$store.dispatch('modal/closeModal')"
      />
  </div>
</template>

<script>
import BaseButton from '../base/BaseButton.vue'
export default {
  components: { BaseButton },
  name: 'AutoCheckModal',
  props: {
    message: {
      type: String,
      default() {
        return ''
      }
    }
  },
  methods: {
    purgeError() {
      this.$store.dispatch('purgeError')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
